//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { storeCategories } from '../types';
export default {
  props: {
    points: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      infoWinOpen: false,
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoContent: '',
      currentMidx: null
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    storeCategories() {
      return storeCategories[this.currentLanguage];
    },
    entitiesCategories() {
      return this.storeCategories.reduce((acc, item) => {
        return (acc = { ...acc, [item.id]: item.name });
      }, {});
    },
    renderCondition() {
      return this.points.length > 0 && this.points.every(item => item.LAT && item.LONG);
    }
  },
  methods: {
    getPosition(value) {
      return {
        lat: parseFloat(value.LAT),
        lng: parseFloat(value.LONG)
      };
    },
    isPointName(value) {
      return ['ENG', 'JPN', 'CHI', 'KOR'].some(item => item === value);
    },
    isInformation(value) {
      return value.toString().includes('INFO_', 0);
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = {
        lat: parseFloat(marker.LAT),
        lng: parseFloat(marker.LONG)
      };
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent(marker) {
      return `<div class="">
          <div>
            <div>
              <div class="m-2"><span style="font-weight: bold;">${this.$t('Point Name')}: </span>
                ${marker.JPN || marker.ENG || marker.KOR || marker.CHI || 'No point name'}
              </div>
            </div>
          </div>
        </div>`;
    }
  }
};
